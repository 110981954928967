








































































































































































    import { Component, Watch } from 'vue-property-decorator';
    import { mixins } from 'vue-class-component';
    import Notifications from '@/components/mixins/Notifications';
    import { copyTextToClipboard } from '@/utils/helpers';
    import VcSwitch from '@/components/VCSwitch.vue';
    import { LiveChatSetting } from '@/domain/model/types';
    import ChatInfoUser from '@/components/ChatInfoUser.vue';
    import DialogAddDefaultBackupContact from '@/components/DialogAddDefaultBackupContact.vue';
    import { settingsStore } from "@/store/modules/settings";
    import {applicationStore} from '@/store/modules/application';
    import rfdc from 'rfdc';
    const clone = rfdc({ proto: true })

    @Component({
        name: 'settings-live-chat',
        components: { VcSwitch, ChatInfoUser, DialogAddDefaultBackupContact }
    })
    export default class PigeonLiveChat extends mixins(Notifications) {

        liveChat: LiveChatSetting = {
            active: false,
            contactDisplay: true,
            defaultContact: false,
            contacts: []
        }

        showDialogAddDefaultContact: boolean = false

        //code
        code: string = `&lt;div id="appSwitcher"&gt;&lt;/div&gt;<br>
&lt;div id="widgetBusinessId" name="${applicationStore.business?.name}" style="display:none"&gt;${applicationStore.business?.id}&lt;/div&gt;<br>
&lt;link rel="stylesheet" href="https://pigeon-widget.web.app/main.css"&gt;<br>
&lt;script type="module" src="https://pigeon-widget.web.app/main.js"&gt;&lt;/script&gt;<br>&lt;!-- Customizing widget properties  --&gt;<br>
&lt;style type="text/css"&gt;#getPigeon{ bottom: 0px;right: 0px; }&lt;/style&gt;`;


        //code transform
        codeTransform() {
            return this.code.replaceAll('&lt;', '<')
                .replaceAll('&gt;', '>')
                .replaceAll('<br>', '')
        }

        copyCode() {
            copyTextToClipboard(this.codeTransform())
            this.showInfo('Script code copied to clipboard', 'chain')
        }

        switchActive() {
            this.liveChat.active = !this.liveChat.active
        }

        onAddDefaultContact() {
            this.showDialogAddDefaultContact = true
        }

        onRemoveContact(id) {
            this.liveChat.contacts?.splice(this.liveChat.contacts.findIndex(item => item.id === id), 1);
        }

        onDefaultContactSelected(contacts) {
            
            console.log(contacts[0]?.associate);

            contacts.forEach((contact, indx) => {

                //check duplicate
                const isDuplicate = this.liveChat.contacts?.findIndex(item => item.id === contact.id);

                // make list
                if (isDuplicate == -1) {
                    let data = {
                        id: contact.id,
                        uid: contact.associate.id,
                        name: contact.name,
                        email: contact.email,
                        position: contact.position,
                        online: contact?.associate?.status?.online ?? false,
                        rules: contact?.rules
                    }
                    if (contact.photoUrl) {
                        data['photoUrl'] = contact.photoUrl
                    }

                    this.liveChat.contacts?.push(data)
                }
            })
        }

        compareChange() {
            return true
        }

        async onSave() {
            if (!this.compareAway()) {
                await settingsStore.changeLiveChat(clone(this.liveChat))
            }
            this.showInfo('LiveChat was changed')
        }

        compareAway() {
            return JSON.stringify(this.liveChat) === JSON.stringify(settingsStore.businessSettings?.liveChat)
        }

        get disabled() {
            return this.compareAway()
        }

        onDiscard() {
            this.init()
        }

        init() {
            this.liveChat = clone(settingsStore.businessSettings?.liveChat)
        }

        created() {
            this.init()
        }
    }
